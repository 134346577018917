import Image from 'next/image';
import React from 'react';

import { MobileMenu, TMobileMenuItem } from './MobileMenu';
import { TAction } from '../../../types';

import type { TMegaMenuHeaderItem } from '../HeaderMegaMenu';

type TDropdownItem = Required<TMegaMenuHeaderItem>['dropDown'][number];

export type TDropdownMobileMenuProps = {
  onClickClose: () => void;
  onClickBack: () => void;
  mobileActionButton?: TAction;
  selectedDropdownItem: TDropdownItem;
};

export const DropdownMobileMenu: React.FC<TDropdownMobileMenuProps> = ({
  selectedDropdownItem,
  onClickClose,
  onClickBack,
  mobileActionButton,
}) => {
  const dropdownMenuItems = (selectedDropdownItem.rowBody || [])
    .map<TMobileMenuItem>(item => ({
      title: item.link?.[0]?.text || '',
      href: String(item.link?.[0].href || '#'),
      disabled: !!item.link?.[0]['aria-disabled'],
      icon:
        item.icon && item.showIconOnMobile ? (
          <Image {...item.icon} width={24} height={24} className='h-6 w-6' />
        ) : undefined,
    }))
    .filter(item => item.title);

  return (
    <MobileMenu
      title={selectedDropdownItem.rowTitle}
      menuItems={dropdownMenuItems}
      onClickBack={onClickBack}
      onClickClose={onClickClose}
      level={3}
      mobileActionButton={mobileActionButton}
    />
  );
};
