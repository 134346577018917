import { getGaId } from '@front/shared';
import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

type TLinkElementProps = {
  link:
    | (LinkProps & {
        text: string;
        dataGaId?: string;
        'aria-disabled'?: boolean;
      })[]
    | null;
  id?: string;
  description?: string;
  iconHasLightVariant?: boolean;
  icon?: ImageProps | null;
};

export const LinkElement: React.FC<TLinkElementProps> = ({
  description,
  icon,
  link,
  iconHasLightVariant,
}) => {
  const router = useRouter();
  const { text, ...linkProps } = link?.[0] || { href: '', text: '' };

  const isActive =
    router.asPath === linkProps.href && !linkProps['aria-disabled'];

  return (
    <Link
      data-ga-id={getGaId(linkProps?.dataGaId || text, 'header_DropDown')}
      {...linkProps}
      className={clsx(
        { 'opacity-40': link?.[0]?.['aria-disabled'] },
        {
          'border-color-header-submenu-link-title pointer-events-none rounded-xl border-[2px]':
            isActive,
        },
        'flex focus:outline-none',
      )}
    >
      <div className='flex w-full flex-row gap-4 rounded-2xl p-4 hover:bg-[#0000000A]'>
        {icon && (
          <Image
            {...icon}
            width={16}
            height={16}
            className={clsx('h-4 w-auto', {
              'header-icon-invert': !!iconHasLightVariant,
            })}
          />
        )}

        <div>
          <div className='text-header-submenu-link-title text-sm font-medium leading-normal'>
            {text}
          </div>
          {description && (
            <div className='text-header-submenu-content mt-2 text-sm font-normal leading-[1.25rem]'>
              {description}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
