import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';
import {
  forwardRef,
  useContext,
  useRef,
  useState,
  createContext,
  HTMLProps,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
  FocusEvent,
  ButtonHTMLAttributes,
  MouseEvent,
  FunctionComponent,
  SVGProps,
  useCallback,
} from 'react';

import { ReactComponent as IconArrowUp } from '../../../../public/icons/icon-dropdown-16.svg';
import { useScrollBlock } from '../../../hooks/useScrollBlock';

const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  setHasFocusInside: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => null,
  setHasFocusInside: () => null,
  isOpen: true,
});

interface IMenuProps {
  label: string;
  nested?: boolean;
  isOpenOnClick?: boolean;
  isJurisdictionButton?: boolean;
  children?: ReactNode;
  Icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  dataGaId?: string;
  buttonClassName?: string;
  iconClassName?: string;
  chevronClassName?: string;
  menuWidth?: number;
  pageKeyId: string;
}

export const MenuComponent = forwardRef<
  HTMLButtonElement,
  IMenuProps & HTMLProps<HTMLButtonElement>
>(
  (
    {
      children,
      label,
      isOpenOnClick = false,
      isJurisdictionButton = false,
      Icon,
      dataGaId,
      buttonClassName,
      iconClassName,
      chevronClassName,
      menuWidth,
      pageKeyId,
      ...props
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasFocusInside, setHasFocusInside] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);
    const parent = useContext(MenuContext);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const item = useListItem();
    const scrollBlock = useScrollBlock();
    const toggleOverlay = useCallback(
      (value: boolean): void => {
        setIsOpen(value);
        const scrollbarWidth =
          window.innerWidth - document.documentElement.clientWidth;
        const header = document.querySelector('header');
        document.documentElement.classList.toggle('overflow-y-hidden', value);
        document.documentElement.style.paddingRight = value
          ? `${scrollbarWidth}px`
          : '';
        if (header) {
          header.classList.toggle('hover:!bg-white', value);
        }
        value ? scrollBlock.block() : scrollBlock.allow();
      },
      [scrollBlock],
    );

    useEffect(() => {
      if (isOpen) {
        scrollBlock.allow();
      }
    }, [pageKeyId, isOpen, scrollBlock, toggleOverlay]);

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
      nodeId,
      open: !isOpenOnClick ? isOpen : undefined,
      onOpenChange: !isOpenOnClick ? toggleOverlay : undefined,
      placement: 'bottom-start',
      middleware: [
        offset(({ rects }) => ({
          mainAxis: 20,
          alignmentAxis: -rects.floating.width,
        })),
        size({
          apply({ availableWidth, elements }) {
            elements.floating.style.width = `${availableWidth}px`;
            elements.floating.style.top = '-4px';
          },
        }),
        flip(),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
      strategy: 'fixed',
    });

    const click = useClick(context, {
      event: 'mousedown',
      toggle: isOpen,
    });
    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context, { bubbles: true });
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      onNavigate: setActiveIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([click, role, dismiss, listNavigation]);

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId });
      }
    }, [tree, isOpen, nodeId, parentId, isOpenOnClick]);

    useEffect(() => {
      if (!isOpenOnClick) return;

      const closeDropdown = (event: Event): void => {
        const node = document.querySelector('button[data-open]');
        const floatingMenu = document.querySelector('div[role="menu"]');
        if (!node || !floatingMenu) return;

        const targetInNode =
          node.contains(event.target as Node) ||
          floatingMenu.contains(event.target as Node);
        const targetIsNode =
          node === event.target || floatingMenu === event.target;
        if (targetInNode || targetIsNode) return;

        toggleOverlay(false);
      };

      window.addEventListener('click', closeDropdown);

      return () => {
        window.removeEventListener('click', closeDropdown);
      };
    }, [isOpenOnClick, label, toggleOverlay]);

    const isLargeContent = menuWidth && menuWidth > 892 && label.length > 8;

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      setIsVisible(true);
    }, [label, isLargeContent]);

    return (
      <FloatingNode id={nodeId}>
        <button
          title={isLargeContent && label ? label : undefined}
          ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
          data-open={isOpen ? '' : undefined}
          data-focus-inside={hasFocusInside ? '' : undefined}
          data-ga-id={dataGaId}
          className={clsx(
            'relative flex h-10 w-full max-w-fit items-center gap-1.5 rounded-full py-2.5 text-sm font-medium leading-loose text-light-control-950 outline-none transition-opacity hover:bg-control-100',
            {
              'px-4': !isJurisdictionButton,
              'rounded-full px-2 hover:bg-light-control-100':
                isJurisdictionButton,
              '!max-w-[125px]': isLargeContent,
              '!max-w-[144px]': !isLargeContent,
              'opacity-0': !isVisible,
              'opacity-100': isVisible,
            },
            buttonClassName,
          )}
          {...getReferenceProps(
            parent.getItemProps({
              ...props,
              onFocus(event: FocusEvent<HTMLButtonElement>) {
                props.onFocus?.(event);
                setHasFocusInside(false);
                parent.setHasFocusInside(true);
              },
            }),
          )}
          onClick={() => {
            if (!isOpenOnClick) return;
            toggleOverlay(!isOpen);
          }}
        >
          {label ? (
            <span
              className={clsx(
                isLargeContent &&
                  'overflow-hidden text-ellipsis whitespace-nowrap',
              )}
            >
              {label}
            </span>
          ) : null}
          {Icon ? (
            <Icon className={iconClassName} />
          ) : (
            <IconArrowUp
              width={16}
              height={16}
              className={clsx(!isOpen && 'rotate-180', chevronClassName)}
            />
          )}
        </button>
        <MenuContext.Provider
          value={{
            activeIndex,
            setActiveIndex,
            getItemProps,
            setHasFocusInside,
            isOpen,
          }}
        >
          <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager context={context} modal={false}>
                  <div
                    onClick={() => {
                      if (isOpenOnClick) return;
                      toggleOverlay(false);
                    }}
                    ref={refs.setFloating}
                    className='scrollbar-hidden text-header-submenu-link-title z-50 flex w-max flex-col overflow-y-auto bg-white px-28 pb-16 pt-9 text-sm font-medium leading-normal shadow-md outline-none 2xl:max-h-[calc(100vh-80px)] 2xl:px-0'
                    style={floatingStyles}
                    {...getFloatingProps()}
                  >
                    <div
                      className='bg-white 2xl:mx-auto 2xl:w-full 2xl:max-w-[1232px]'
                      onClick={e => {
                        if (isOpenOnClick) return;
                        e.stopPropagation();
                      }}
                    >
                      {children}
                    </div>
                  </div>
                </FloatingFocusManager>
                {/* backdrop */}
                <div
                  className='fixed inset-0 z-30 bg-interface-1000 bg-opacity-50'
                  onClick={() => {
                    if (!isOpenOnClick) return;
                    toggleOverlay(!isOpen);
                  }}
                />
              </FloatingPortal>
            )}
          </FloatingList>
        </MenuContext.Provider>
      </FloatingNode>
    );
  },
);

interface IMenuItemProps {
  label: string;
  disabled?: boolean;
}

export const MenuItem = forwardRef<
  HTMLButtonElement,
  IMenuItemProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ label, disabled, ...props }, forwardedRef) => {
  const menu = useContext(MenuContext);
  const item = useListItem({ label: disabled ? null : label });
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;

  return (
    <button
      {...props}
      ref={useMergeRefs([item.ref, forwardedRef])}
      type='button'
      role='menuitem'
      className='MenuItem'
      tabIndex={isActive ? 0 : -1}
      disabled={disabled}
      {...menu.getItemProps({
        onClick(event: MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event);
          tree?.events.emit('click');
        },
        onFocus(event: FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event);
          menu.setHasFocusInside(true);
        },
      })}
    >
      {label}
    </button>
  );
});

const DropdownMenu = forwardRef<
  HTMLButtonElement,
  IMenuProps & HTMLProps<HTMLButtonElement>
>((props, ref) => {
  return <MenuComponent {...props} ref={ref} />;
});

export default DropdownMenu;
