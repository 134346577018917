/* eslint-disable react/jsx-no-useless-fragment */
import clsx from 'clsx';
import React from 'react';

import { ESocialMediaIconStyle } from './../../SocialMedia';
import { LinkElement } from './LinkElement';
import { Topline } from './Topline';
import { TMenuCardProps } from './types';
import { SocialMediaList, TSocialMediaListProps } from '../../SocialMediaList';

export const getSpan = (
  rowRender: TMenuCardProps['rowRender'],
): number | null => {
  switch (rowRender) {
    case '1col':
      return 1;
    case '2col':
      return 2;
    case '3col':
      return 3;
    case '4col':
      return 4;
    default:
      return null;
  }
};

export const MenuCard: React.FC<TMenuCardProps & TSocialMediaListProps> = ({
  rowTitle,
  rowIcon,
  rowRender,
  rowBody,
  hasTopline,
  longTopline,
  companyLinks,
  socialsTitle,
}) => {
  const span = getSpan(rowRender);

  // render as tiles
  if (!span) {
    return (
      <>
        {rowBody?.map((rowBodyItem, i) => (
          <div key={i}>
            {hasTopline && (
              <Topline
                rowTitle={rowTitle}
                rowIcon={rowIcon}
                showTitle={i === 0}
                isLong={i !== rowBody.length - 1 || longTopline}
              />
            )}
            <LinkElement key={i} {...rowBodyItem} />
          </div>
        ))}
      </>
    );
  }

  // render as span group
  return (
    <div
      className={clsx({
        'col-span-4': !span || span === 4,
        'col-span-3': span === 3,
        'col-span-2': span === 2,
        'col-span-1': span === 1,
      })}
    >
      {hasTopline && (
        <Topline
          rowTitle={rowTitle}
          isLong={longTopline}
          rowIcon={rowIcon}
          showTitle={true}
        />
      )}

      <div
        className={clsx('grid gap-4', {
          'grid-cols-4': !span || span === 4,
          'grid-cols-3': span === 3,
          'grid-cols-2': span === 2,
          'grid-cols-1': span === 1,
        })}
      >
        {rowBody?.map((rowBodyItem, i) => (
          <LinkElement key={i} {...rowBodyItem} />
        ))}
        {companyLinks ? (
          <div className='flex items-start gap-4'>
            {socialsTitle ? (
              <h3 className='whitespace-nowrap pt-4 text-sm leading-[20px] text-interface-800'>
                {socialsTitle}
              </h3>
            ) : null}
            <SocialMediaList
              companyLinks={companyLinks}
              iconStyle={ESocialMediaIconStyle.Header}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
